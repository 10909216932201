<template>
	<div class="containerx">
		<!--  环境检测  -->
		<div class="nyjc">
			<!-- <div class="title">环境检测</div> -->
			<div class="btngrp">
				<!--        <div @click="hjjc(item, index)" class="btn" :class="hjjc_current==index?'btn1':'btn2'"
          v-for="(item, index) in hjjc_arr">
          {{item.name}}
        </div> -->

				<div>
					<label style="font-size: 1.8rem;margin-left:1rem;">设备类型</label>&nbsp;
					<template>
						<el-select class="monitor-select" v-model="eIndex" filterable :clearable="false" placeholder="请选择"
							@change="onChange" :popper-append-to-body="false">
							<el-option v-for="item in hjjc_arr" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</template>
				</div>
			</div>
			<div class="title">实时数据</div>
			<div class="sssj">
				<div v-if="sensor_list.length == 0" class="notice_msg">没有该类设备，或设备还未上传数据</div>
				<div class="item" v-for="(sensor, index) in  sensor_list">
					<div @click="sssj_current = index">
						<div class="img1"
							:style="{ backgroundColor: sssj_current == index ? 'rgba(43, 222, 201, 0.8)' : 'unset' }">
							<img v-if="sensor.type == 'TEM'" src="../../../static/wd.png" />
							<img v-if="sensor.type == 'HUM'" src="../../../static/sd.png" />
							<img v-if="sensor.type == 'GAS'" src="../../../static/qt.png" />
							<img v-if="sensor.type == 'VIB'" src="../../../static/zd.png" />
							<img v-if="sensor.type == 'WLE'" src="../../../static/js.png" />
							<img v-if="sensor.type == 'FAU'" src="../../../static/FAU_STA.jpg" />
							<img v-if="sensor.type == 'EXT'" src="../../../static/FAU_STA.jpg" />
						</div>
					</div>
					<div style="position:relative;display: flex;flex-direction: column;justify-content: center;">
						<img class="img2" @click="sssj_current = index"
							:src="sssj_current == index ? require('@/static/sssj2c.png') : require('@/static/sssj2.png')">
						<p v-if="sensor.type == 'TEM'" class="t1">温度{{ sensor.orderNum > 1 ? sensor.orderNum : '' }}</p>
						<p v-if="sensor.type == 'HUM'" class="t1">湿度{{ sensor.orderNum > 1 ? sensor.orderNum : '' }}</p>
						<p v-if="sensor.type == 'GAS'" class="t1">气体{{ sensor.orderNum > 1 ? sensor.orderNum : '' }}</p>
						<p v-if="sensor.type == 'VIB'" class="t1">震动{{ sensor.orderNum > 1 ? sensor.orderNum : '' }}</p>
						<p v-if="sensor.type == 'WLE'" class="t1">浸水{{ sensor.orderNum > 1 ? sensor.orderNum : '' }}</p>
						<p v-if="sensor.type == 'FAU'" class="t1">故障状态{{ sensor.orderNum > 1 ? sensor.orderNum : '' }}</p>
						<p v-if="sensor.type == 'EXT'" class="t1">灭火装置{{ sensor.orderNum > 1 ? sensor.orderNum : '' }}</p>
						<p v-if="sensor.currentValue != null && sensor.currentValue != 5001.00 && sensor.type != 'VIB' && sensor.type != 'WLE' && sensor.type != 'FAU' && sensor.type != 'EXT' && sensor.type != 'TEM' && sensor.type != 'HUM'"
							class="t2">
							{{ sensor.currentValue }}&nbsp;{{ sensor.unit }}</p>
						<p v-if="sensor.currentValue != null && sensor.currentValue != 5001.00 && (sensor.type == 'TEM' || sensor.type == 'HUM')"
							class="t2">
							{{ sensor.currentValue % 1 === 0 ? sensor.currentValue + '.0' : sensor.currentValue }}&nbsp;{{ sensor.unit }}
						</p>
						<p v-if="sensor.currentValue != null && sensor.currentValue == 5001.00 && sensor.type != 'VIB' && sensor.type != 'WLE' && sensor.type != 'FAU' && sensor.type != 'EXT'"
							class="t2">
							{{ '00' }}&nbsp;{{ sensor.unit }}</p>

						<p v-if="sensor.currentValue != null && sensor.currentValue == 5001.00 && (sensor.type == 'VIB' || sensor.type == 'WLE' || sensor.type == 'FAU' || sensor.type == 'EXT')"
							class="t2">
							断开</p>
						<p v-if="sensor.currentValue != null && sensor.currentValue != 5001.00 && (sensor.type == 'VIB' || sensor.type == 'WLE' || sensor.type == 'FAU' || sensor.type == 'EXT')"
							class="t2">
							连接</p>
						<div
							style="width: 20rem;height: 2.6rem;display: flex;justify-content: space-between;align-items: center;margin-left: 0.9rem;">
							<p v-if="!sensor.valueSta && sensor.currentValue != 5001.00"
								style="color: rgba(31, 227, 229, 1);font-size: 0.9rem;">状态：正常</p>
							<p v-if="sensor.valueSta && sensor.currentValue != 5001.00 && sensor.type != 'VIB' && sensor.type != 'WLE' && sensor.type != 'FAU' && sensor.type != 'EXT'"
								style="color: rgba(31, 227, 229, 1);font-size: 0.9rem;">状态：异常</p>
							<p v-if="sensor.valueSta && sensor.currentValue != 5001.00 && sensor.type == 'VIB'"
								style="color: rgba(31, 227, 229, 1);font-size: 0.9rem;">状态：震动报警</p>
							<p v-if="sensor.valueSta && sensor.currentValue != 5001.00 && sensor.type == 'WLE'"
								style="color: rgba(31, 227, 229, 1);font-size: 0.9rem;">状态：浸水报警</p>
							<p v-if="sensor.valueSta && sensor.currentValue != 5001.00 && sensor.type == 'FAU'"
								style="color: rgba(31, 227, 229, 1);font-size: 0.9rem;">状态：故障报警</p>
								<p v-if="sensor.valueSta && sensor.currentValue != 5001.00 && sensor.type == 'EXT'"
								style="color: rgba(31, 227, 229, 1);font-size: 0.9rem;">状态：未运行</p>
							<p v-if="sensor.currentValue != null && sensor.currentValue == 5001.00"
								style="color: rgba(31, 227, 229, 1);font-size: 0.9rem;">状态：传感器断开</p>
							<p @click="delSensor(sensor.id)"
								v-if="sensor.currentValue != null && sensor.currentValue == 5001.00"
								style="font-size: 0.9rem;cursor: pointer;">删除</p>
						</div>
					</div>
				</div>
			</div>
			<!-- 日期选择器 -->
			<div class="date-select">
				<el-date-picker @change="dateChange" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" v-model="date"
					type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" :picker-options="pickerOptions">
				</el-date-picker>
			</div>
			<!--   图表   -->
			<div id="myChart" style="width: 100%;height: 42.5rem"></div>
		</div>
		<!-- 设备列表 -->
		<div class="sblb">
			<div class="title">设备列表</div>
			<div class="table">
				<div class="tr bg">
					<div class="td1">
						<p>序号</p>
					</div>
					<div class="td2">
						<p>设备名称</p>
					</div>
					<div class="td3 td">
						<p>设备编号</p>
					</div>
					<div class="td4 td">
						<p>物联编号</p>
					</div>
					<!-- 					<div class="td4 td">
						<p>规格型号</p>
					</div>
					<div class="td5 td">
						<p>电压等级</p>
					</div> -->
					<div class="td6 td">
						<p>设备状态</p>
					</div>
					<div class="td7 td">
						<p>生产日期</p>
					</div>
					<div class="td8 td">
						<p>生产厂家</p>
					</div>
				</div>
				<div @click="handleTable(item)" :style="{ backgroundColor: facilityId == item.id ? '#00a6a6' : 'unset' }"
					style="cursor: pointer;" class="tr" v-for="(item, index) in tableData"
					:class="index % 2 == 0 ? 'bg1' : 'bg2'">
					<div class="bg1" style="display: flex; width: 100rem;">
						<div class="td1">
							<p>{{ index + 1 }}</p>
						</div>
						<div class="td2">
							<div class="z1">
								<div class="item-show">{{ item.name }}</div>
								<div class="hidden-text">{{ item.name }}</div>
							</div>
						</div>
						<div class="td3 td">
							<div class="z1">
								<div class="item-show">{{ item.serialNumber }}</div>
								<div class="hidden-text">{{ item.serialNumber }}</div>
							</div>
						</div>
						<div class="td4 td">
							<div class="z1">
								<div class="item-show">{{ item.monitorSerialNumber }}</div>
								<div class="hidden-text">{{ item.monitorSerialNumber }}</div>
							</div>
						</div>
						<!-- 						<div class="td4 td">
							<div class="z1">
								<div class="item-show">{{item.specifications}}</div>
								<div class="hidden-text">{{item.specifications}}</div>
							</div>
						</div>
						<div class="td5 td">
							<p>{{item.voltageLevel}}</p>
						</div> -->
						<div class="td6 td">
							<p>{{ rtState(item.state) }}</p>
						</div>
						<div class="td7 td">
							<p>{{ item.productionData }}</p>
						</div>
						<div class="td8 td">
							<div class="z1">
								<div class="item-show">{{ item.manufactor }}</div>
								<div class="hidden-text">{{ item.manufactor }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination style="color: white" background :page-sizes="[10, 15, 20]" @current-change="currentChange"
					@size-change="sizeChange" :page-size="facility_size" :current-page.sync="facility_page"
					layout="total, sizes, prev, pager, next, jumper" :total="facility_total">
				</el-pagination>
			</div>

		</div>

	</div>
</template>

<script>
import * as echarts from 'echarts';
export default {
	name: 'index1',
	props: {
		projectId: {
			type: Number,
			default: null
		},
		projectId_cx: {
			type: Number,
			default: null
		},
		levelId: {
			type: Number,
			default: null
		}
	},
	data() {
		return {
			sensor_list: [],
			tableData: [],
			sssj_current: 0,
			hjjc_arr: ['配电柜', '环网柜', '防爆盒', '变压器', '电缆接头'],
			hjjc_current: 0,
			facility_type: 1,
			facility_page: 1,
			facility_size: 10,
			facility_total: 0,
			// 设备id
			facilityId: 1,
			sssj_obj: {},
			// 图标横纵坐标
			xData: [],
			yData: [],
			// 日期
			beginTime: '',
			endTime: '',
			date: ['', ''],
			eIndex: '',
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			equipmentIndex: '1'
		}
	},
	methods: {
		handleTable(item) {
			this.facilityId = item.id
			this.getNewData()
		},
		// 根据设备id查询环境设备最新的数据
		getNewData() {
			if (this.facilityId == null) {
				this.sensor_list = []
				this.sssj_obj = {}
				this.xData = []
				this.yData = []
				this.mycharts()
			} else {
				this.$newGet('/online/monitoring/newest/environment/' + this.facilityId).then(res => {
					if (res.data != null) {
						this.sensor_list = res.data
					} else {
						this.sensor_list = []
					}
					this.getCharts()
					this.$message({
						message: '访问成功',
						type: 'success'
					});
				})
			}

		},
		//select设备分类
		onChange(res) {
			this.sssj_current = 0
			this.facility_type = res
			this.getFacility()
		},
		// 环境设备
		getHjjc() {
			this.$newPost('/equipment/type/list/' + 0).then(res => {
				this.hjjc_arr = res.data
				this.eIndex = res.data[0].id
				this.facility_type = res.data[0].id
				this.getFacility()
			})
		},

		//删除传感器
		delSensor(sensorId) {
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$newPost('/equipment/sensor/del/' + sensorId).then(res => {
					if (res.success) {
						this.$message({
							message: '删除成功',
							type: 'success'
						});
						this.getNewData();
					} else {
						this.$message({
							message: '删除失败',
							type: 'error'
						});
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},

		// 设备状态：0-在线 1-离线 2-异常
		rtState(num) {
			switch (num) {
				case 0:
					return '在线'
					break;
				case 1:
					return '离线'
					break;
				default:
					return '异常'
			}
		},
		hjjc(item, index) {
			this.sssj_current = 0
			this.hjjc_current = index
			this.facility_type = item.id
			this.getFacility()
		},
		// 设备列表
		getFacility() {
			var obj = {
				"equipmentTypeId": this.facility_type,
				"levelId": this.levelId,
				"page": this.facility_page,
				"projectId": this.projectId_cx,
				"size": this.facility_size,
			}
			this.$newPost('/online/monitoring/page', obj).then(res => {
				this.tableData = res.data.records
				this.facility_total = res.data.total
				if (res.data.records.length > 0) {
					this.facilityId = res.data.records[0].id
				} else {
					this.facilityId = null
				}
				this.getNewData()
			})
		},
		currentChange(e) {
			console.log(e)
			this.facility_page = e
			this.getFacility()
		},
		sizeChange(e) {
			this.facility_size = e
			this.getFacility()
		},
		// 图表
		mycharts() {
			var chartDom = document.getElementById('myChart');
			var myChart = echarts.init(chartDom);
			var option;

			option = {
				// 提示框
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						label: {
							backgroundColor: '#2BDEC9'
						}
					}
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: this.xData
				},
				yAxis: {
					type: 'value'
				},
				series: [{
					areaStyle: {

					},
					itemStyle: {
						normal: { //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: 'rgba(47, 241, 216, 0.5)' // 0% 处的颜色
							}, {
								offset: 0.5,
								color: 'rgba(47, 241, 216, 0.3)' // 100% 处的颜色
							}, {
								offset: 1,
								color: 'rgba(76, 247, 255, 0.1)' // 100% 处的颜色
							}]), //背景渐变色
							lineStyle: { // 系列级个性化折线样式
								width: 3,
								type: 'solid',
								color: "#4fd6d2"
							}
						},
					},
					data: this.yData,
					type: 'line',
					areaStyle: {}
				}]
			};

			option && myChart.setOption(option);
		},
		// 获取数据和图表
		getCharts() {

			var obj = {
				"beginTime": this.beginTime,
				"sensorId": this.sensor_list.length == 0 ? 0 : this.sensor_list[this.sssj_current].id,
				"endTime": this.endTime,
				"equipmentId": this.facilityId
			}
			this.$newPost('/online/monitoring/environment/data/statistics', obj).then(res => {
				console.log(res, 'rrr')
				if (res.code == 2000) {
					if (res.data.length > 0) {
						this.xData = []
						this.yData = []
						res.data.forEach((item, index) => {
							this.xData.push(item.gmtCreate)
							this.yData.push(item.value)
						})
						console.log(this.xData)
						setTimeout(() => {
							this.mycharts()
						})
					} else {
						this.xData = []
						this.yData = []
						this.mycharts()
					}
				} else {
					this.xData = []
					this.yData = []
					this.mycharts()
				}
			})

		},
		dateChange(e) {
			this.beginTime = e[0]
			this.endTime = e[1]
			this.getCharts()
		},
	},
	mounted() {
		this.getHjjc()
		this.getCharts()
	},
	watch: {
		sssj_current(val) {
			this.getCharts()
		},
	},

}
</script>

<style lang="scss" scoped>
.bg {
	background-color: rgba(43, 201, 222, 0.6);
}

.bg1 {
	background-color: rgba(162, 162, 162, 0.1);
}

.bg2 {
	background: rgba(14, 242, 242, 0.25);
}

.containerx {
	color: #ffffff;
	overflow: auto;
}

.sblb {
	background-color: #192534;
	margin: 2rem 0;
}

.nyjc {
	background-color: #192534;

	.btngrp {
		margin-left: 1rem;
		margin-top: 1rem;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.btn {
			min-width: 8rem;
			height: 5rem;
			line-height: 5rem;
			padding: 0 .5rem;
			text-align: center;
			// background: rgba(43, 201, 222, 0.6);
			font-size: 1.5rem;
			margin-right: 3rem;
			cursor: pointer;
			margin-bottom: 2rem;
			border-radius: 1rem;
		}

		.btn1 {

			background: rgba(43, 201, 222, 0.6);

		}

		.btn2 {
			// width: 15rem;
			// height: 8.4rem;
			// line-height: 8.4rem;
			// text-align: center;
			background: rgba(43, 222, 201, 0.6);
			// font-size: 1.8rem;
			// margin-right: 3rem;
			// cursor: pointer;
			// margin-bottom: 2rem;
			// border-radius: 1rem;
		}
	}

	.sssj {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.item {
			display: flex;
			margin-right: 3rem;
			margin-top: 1rem;

		}

		.img2 {
			width: 20rem;
			height: 3.6rem;
			margin-left: 0.9rem;
			cursor: pointer;
		}

		.img1 {
			width: 6.4rem;
			height: 6.6rem;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 0.2rem solid rgba(43, 222, 201, 0.6);
			border-radius: 0.6rem;
			cursor: pointer;

			img {
				width: 4rem;
				height: 4rem;
			}
		}

		.t1 {
			position: absolute;
			top: 1.05rem;
			left: 1.8rem;
			font-size: 1.6rem;
		}

		.t2 {
			position: absolute;
			top: 1.05rem;
			right: 1.5rem;
			font-size: 1.6rem;
		}
	}
}

.title {
	font-size: 1.8rem;
	font-weight: 500;
	margin-left: 2rem;
	//margin-top: 2rem;
	height: 5rem;
	line-height: 5rem;
}

.table {
	width: 100rem;
	height: 34rem;
	overflow: auto;
	margin: 0 auto;

	.tr {
		// background: rgba(43, 201, 222, 1);
		display: flex;
		margin-top: 1rem;

		.td1 {
			font-size: 0.9rem;
			width: 6.6rem;
			height: 2rem;
			margin-right: 0.4rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.td2 {
			font-size: 0.9rem;
			width: 30rem;
			height: 2rem;
			display: flex;
			align-items: center;
			padding-left: 2rem;
			margin-right: 0.4rem;
		}

		.td {
			font-size: 0.9rem;
			height: 2rem;
			display: flex;
			align-items: center;
			padding-left: 2rem;
			margin-right: 0.4rem;
		}

		.td3 {
			width: 30rem;
		}

		.td4 {
			width: 20.8rem;
			overflow: hidden;
		}

		.td5 {
			width: 13.6rem;
		}

		.td6 {
			width: 16rem;
		}

		.td7 {
			width: 22.4rem;
		}

		.td8 {
			width: 26.2rem;
		}
	}

	// .tr:hover {
	//   background-color: rgba(117, 117, 117, 0.1);
	// }
}

.pagination {
	display: flex;
	justify-content: flex-end;
	margin-right: 1rem;
}

.date-select {
	margin-left: 15rem;
	margin-top: 4rem;
	margin-bottom: 2rem;

	/deep/ .el-input__inner {
		border-color: #2BDEC9;
		background-color: unset;
		color: #2BDEC9;

		.el-range-separator {
			color: #2BDEC9;
		}

		.el-input__icon {
			color: #2BDEC9;
		}

		input {
			background-color: unset;
			color: #2BDEC9;
		}
	}
}

.notice_msg {
	display: flex;
	font-size: 1.8rem;
	width: 100%;
	justify-content: center;
}

.z1 {
	width: 100%;
	height: 2rem;

	.item-show {
		height: 2rem;
		line-height: 2rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.hidden-text {
		font-size: 1.7rem;
		position: relative;

		// width: 25rem;
		line-height: 2.2rem;
		z-index: -2;
		top: -2rem;
		padding: 8px 8px 8px 8px;
		border-radius: .2rem;
	}

}

.z1:hover .hidden-text {
	// display: show;
	background-color: rgba(8, 157, 157, 1);
	z-index: 999;
}

.monitor-select {
	::v-deep .el-select {
		width: 250px;
	}

	::v-deep .el-input__inner {
		background-color: rgba(43, 222, 201, 0.6);
		color: #fff;
		border: 0px;

	}

	::v-deep .el-select-dropdown__item {
		color: #fff;
	}


	::v-deep .el-scrollbar,
	::v-deep .el-select-dropdown {
		background-color: #192534;
		color: #fff !important;
		border-color: rgba(43, 222, 201, 0.6);
	}

	::v-deep .el-scrollbar__wrap,
	::v-deep .el-select-dropdown__list {
		background-color: #192534;
		color: #fff !important;
	}

	::v-deep .el-select-dropdown__item.hover,
	.el-select-dropdown__item:hover {
		background-color: rgba(43, 222, 201, 0.6);
		color: #fff;
	}
}

.z1 {

	.hidden-text {
		font-size: 1.7rem;
		position: relative;
		display: hidden;
		// width: 25rem;
		line-height: 2.2rem;
		z-index: -1;
		top: -2rem;
		padding: 8px 0px 8px 8px;
		border-radius: .2rem;
	}

}

.z1:hover .hidden-text {
	display: show;
	background-color: rgba(8, 157, 157, 1);
	z-index: 999;
}</style>
