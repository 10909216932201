<template>
  <div class="containerx">
    <div  class="main-box">
      <div class="left-box">
        <div class="left-top-box">
          <span>层级</span>
        </div>
        <div class="left-down-box">
          <el-tree :data="treeData" :props="defaultProps" @node-click="handleNodeClick" :highlight-current="true"></el-tree>
        </div>
      </div>
      <div class="right-box">
          <div class="right-top-box">
            <span class="box-title">设备列表</span>
          <div class="total-count-box">
            <span class="my-icon online"></span><span class="text-lalel">在线设备 <span class="value">{{countValue.online}}</span></span>
            <span class="my-icon offline"></span><span class="text-lalel">离线设备 <span class="value">{{countValue.offline}}</span></span>
            <span class="my-icon err"></span><span class="text-lalel">异常设备 <span class="value">{{countValue.err}}</span></span>
          </div>
          </div>
          <div class="right-down-box" v-loading="loadingEquipment" element-loading-text="拼命加载中"
    element-loading-background="rgba(255, 255, 255, 0.3)" customClass="">
            <span class="notification" v-if="equipmentList.length<1">
              <span >暂无数据</span>
            </span>
            <div class="item-box">
              <div class="item"  v-for="(equipment, index) in  equipmentList">
                <div class="item-top">
                  <span class="monitor-number">设备状态：<span :style="{color: equipment.state==0 ? '#88ff4d':equipment.state==1?'yellow':'#f56c6c'}">{{ equipment.state==0 ? '在线': equipment.state==1?'离线':'异常'}}</span></span>
                  <span class="monitor-number">设备名称：{{equipment.name}}</span>
                  <span class="number">设备编号：{{equipment.serialNumber}}</span>
                </div>
                <div class="space-line"></div>
                <div class="senser-list">
                  
                  <div class="senser-value" v-for="(sensor,index2) in  equipment.equipmentSensorRespList">

                    <span v-if="sensor.type=='GAS'|| sensor.type=='TEM'|| sensor.type=='HUM'">
                      <span style="margin-left: 10px;">{{sensor.name}}：</span><span :style="{color: sensor.currentValue==5001.0?'yellow':sensor.valueSta?'#f56c6c':'#88ff4d'}">{{sensor.currentValue==5001.0?'断开':!sensor.valueSta?sensor.currentValue+" "+sensor.unit:'异常'}}</span>
                    </span>
                    <span v-if="sensor.type=='FAU'|| sensor.type=='VIB'||sensor.type=='EXT'||sensor.type=='WLE'" >
                      <span style="margin-left: 10px;">{{sensor.name}}：</span><span :style="{color: sensor.currentValue==5001.0?'yellow':sensor.valueSta?'#f56c6c':'#88ff4d'}">{{sensor.currentValue==5001.0?'断开':!sensor.valueSta?'正常':'异常'}}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 1rem;">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="queryForm.page"
                :page-size="queryForm.size"
                layout="prev, pager, next, jumper"
                :total="pageTotal">
              </el-pagination>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import { directive } from 'vue/types/umd'
import hjjc from './index3.vue'
  export default {
    name: 'index1',
    components: {
    hjjc,
    // directive
},
    data() {
      return {
        loadingEquipment: false,
        treeData: [],
        defaultProps: {
          children: 'lowerLevelList',
          label: 'levelName'
        },
        equipmentList: [
        ],
        queryForm: {
          page: 0,
          size: 12,
          type: 0,
          levelId: null,
          projectId: 1,
        },
        pageTotal: 0,
        countValue:{
          online: 0,
          offline: 0,
          err: 0
        }
      }
    },
    mounted() {
      this.getTreeData()
      this.countStat()
    },
    methods: {
      getTreeData() {
        // 获取项目列表
        this.$newGet('/level/getByProjectId'
        ).then(res => {
          
          if(res.success){
            this.treeData = res.data
          }
        })
      },
      countStat(){
        this.$newPost('/equipment/count/stat',this.queryForm).then(res=>{
          res.data.forEach((ele,index) => {
            if(1==ele.state){
              this.countValue.offline = ele.number
            }else if(0 == ele.state){
              this.countValue.online = ele.number
            }else if(2 == ele.state){
              this.countValue.err = ele.number
            }
          })

        }).catch(err => {
        })
      },
      handleSizeChange(val) {
        this.loadingEquipment = true
        this.queryForm.pageSize = val
        this.queryPage()
      },
      handleCurrentChange(val) {
        this.loadingEquipment = true
        this.queryForm.page = val
        this.queryPage()
      },
      handleNodeClick(data) {
        this.loadingEquipment = true

        this.queryForm.levelId = data.id
        this.queryPage()
      }
      ,queryPage(){
        this.$newPost('/equipment/page',this.queryForm).then(res=>{
          this.loadingEquipment =false
          this.equipmentList = res.data.records
          this.pageTotal = res.data.total

        }).catch(err => {
          this.loadingEquipment = false
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .bg {
    background-color: rgba(43, 201, 222, 0.6);
  }

  .bg1 {
    background-color: rgba(162, 162, 162, 0.2);
  }

  .bg2 {
    background: rgba(14, 242, 242, 0.3);
  }
  .containerx {
    overflow: auto;
    background-color: #fcfcfc;
    width: 100%;
    height: 100%;

    .main-box{
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
    
      .left-box{
        display: flex;
        flex-direction: column;
        width: 20%;
        height: 100%;
        border-right: 1px solid  #24708dcc;

        .left-top-box{
          padding-left: 20px;
          display: flex;
          align-items: center;
          font-size: 1.3rem;
          width: 100%;
          height: 5rem;
          border-bottom: 1px solid #24708dcc;
          color: #202020;
        }

        .left-down-box{
          width: 100%;
          height: 100%;
        }
      }
      .right-box{
        display: flex;
        width: 80%;
        height: 100%;
        flex-direction: column;

        .right-top-box{
          width: 100%;
          height: 5rem;
          border-bottom: 1px solid #24708dcc;
          display: inline-flex;
          align-items: center;
          justify-content: space-between;

          .my-icon{
            width: 10px;
            height: 10px;
            margin-right: 5px;
            border-radius: 5px;
            border: 1px solid #202020;
          }

          .online{
            background-color: #67C23A;
          }

          .offline{
            background-color: #E6A23C;
          }
          .err{
            background-color: #F56C6C;
          }
          .text-lalel{
            margin-right: 20px;
            
            .value{
              color: #8a8a8a;
            }
          }
          .box-title {
            margin-left: 20px;
            font-size: 1.3rem;
            display: flex;
            color: #202020;
          }
          
          .total-count-box {
            margin-right: 20px;
            font-size: 1.3rem;
            display: flex;
            align-items: center;
          }
        }

        .right-down-box{
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: center;
          align-content: flex-start;
          
          .notification{
            width: 100%;
            height: 20rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #a9a9a9;
            font-size: 1.5rem;
          }

          .item-box {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: center;  
          
            
            .item {
              width:310px;
              // min-height: 250px;
              background-color: #24b2eacc;
              display: flex;
              flex-wrap: wrap;
              align-content: flex-start;
              border-radius: 10px;
              margin-top: 20px;
              margin-left: 10px;
              color: aliceblue;
              justify-content: center;
              
              
              .item-top {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                align-content: flex-start;
                padding-top: 10px;
                padding-left: 10px;

                .number{
                  display: flex;
                  width: 100%;
                  height: 30px;
                  align-items: center;
                }
                .monitor-number{
                  width: 100%;
                  height: 30px;
                  display: flex;
                  align-items: center;
                }
              }
              .space-line{
                height: 2px;
                width: 90%;
                background-color: #ffffff;
                margin: 10px 0 10px 0;
              }

              .senser-list{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;

                .senser-value{
                  width: 50%;
                  height: 30px;
                  display: flex;
                  align-items: center;
                }
              }
              
            }
          }
        }
      }

    }

  }

  .title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: 2rem;
    //margin-top: 2rem;
    height: 5rem;
    line-height: 5rem;
  }
  ::v-deep .el-tree-node__label{
    font-size: 1rem;
  }
  ::v-deep .el-tree-node__expand-icon{
    font-size: 1rem;
  }
  ::v-deep .el-tree{
    margin-top: 0.5rem;
  }
  ::v-deep  .el-tree-node{
    margin-left: 0.5rem;
  }


  

  ::v-deep .el-loading-spinner{ 

  .circular{
    width: 15rem;
    height: 5rem;
  }
  .path{
   stroke:  #24b2eacc;
  }
  
    .el-loading-text{
    color: #24b2eacc;
    font-size: 1.2rem;
  }
}

</style>
